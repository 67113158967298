import react from 'react';

import { acConfig } from '@ac/library-utils/dist/declarations';
import {
  RegionConfiguration,
  RegionsGroupConfiguration,
} from '@ac/library-utils/dist/declarations/config/ac.config';
import { FlexDirection, LayoutProperties } from '@ac/web-components';

import { Body } from './body/Body';
import { Footer } from './footer/Footer';
import { Header } from './header/Header';

import './App.css';

const regions: RegionConfiguration[] = acConfig?.regions?.length
  ? acConfig.regions
  : [
      {
        code: 'EU1',
        domain: acConfig.containerFrontendUrl.substring(
          acConfig.containerFrontendUrl.indexOf('web.') + 4
        ),
        name: 'Europe (Global)',
        global: true,
        regionsGroupCode: 'eu',
      },
    ];

const regionsGroups: RegionsGroupConfiguration[] = acConfig?.regionsGroups
  ?.length
  ? acConfig.regionsGroups
  : [
      {
        code: 'eu',
        name: 'Europe',
      },
    ];

export const App: react.FC = () => (
  <ac-layout layout={LayoutProperties.headerBodyFooter}>
    <ac-layout-item area="header" class="map-header-container">
      <Header regions={regions} />
    </ac-layout-item>
    <ac-layout-item area="body" class="map-body-container overflow-hidden">
      <Body regions={regions} regionsGroups={regionsGroups}></Body>
    </ac-layout-item>
    <ac-layout-item area="footer">
      <ac-flex direction={FlexDirection.column}>
        <Footer />
        <ac-text class="map-footer-rights">
          © {new Date().getFullYear()} Shiji Group. All rights reserved.
        </ac-text>
      </ac-flex>
    </ac-layout-item>
  </ac-layout>
);
