/* eslint-disable simple-import-sort/imports */
import { acConfig } from '@ac/library-utils/dist/declarations';
import { RegionConfiguration } from '@ac/library-utils/dist/declarations/config/ac.config';

export const redirectToRegion = (region: RegionConfiguration): void => {
  if (window.location.origin.includes('localhost')) {
    window.location.assign(`${acConfig.containerFrontendUrl}`);
  } else if (region) {
    window.location.assign(
      `https://${region.code.toLowerCase()}.web.${region.domain}`
    );
  }
};
