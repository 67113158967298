import { redirectToRegion } from 'src/utils/redirectToRegion';

import { RegionConfiguration } from '@ac/library-utils/dist/declarations/config/ac.config';
import {
  AlignItems,
  ButtonPattern,
  DropdownButtonSlot,
  IconName,
  JustifyContent,
} from '@ac/web-components';

import './Header.css';

interface Props {
  regions: RegionConfiguration[];
}

export const Header = ({ regions }: Props): JSX.Element => (
  <ac-flex
    justifyContent={JustifyContent.center}
    class="ac-spacing-left-lg ac-spacing-right-lg ac-spacing-top-lg regions-header"
  >
    <ac-box sizeSm={16} sizeMd={16} sizeLg={14} sizeXlg={10}>
      <ac-flex justifyContent={JustifyContent.spaceBetween} wrapped>
        <ac-icon
          class="regions-header-icon"
          icon={IconName.shiji}
          data-test-selector="regions-header-icon"
        />
        <ac-box sizeSm={16} sizeMd="auto">
          <ac-flex
            alignItems={AlignItems.baseline}
            justifyContent={JustifyContent.center}
            wrapped
          >
            <ac-box
              sizeSm={16}
              sizeMd="auto"
              class="regions-header-text"
              data-test-selector="regions-header-text"
            >
              Select a region on the map or expand the list and select the
              required region
            </ac-box>
            <ac-dropdown-button
              pattern={ButtonPattern.tertiary}
              isArrowVisible={true}
              class="ac-spacing-left-lg"
              isBoundaryContainerApplied={false}
            >
              <ac-button-content
                text="Select region"
                data-test-selector="regions-header-button"
              />
              {regions.map((region, index) => (
                <ac-dropdown-button-item
                  key={index}
                  label={region.name}
                  slot={DropdownButtonSlot.items}
                  onClick={(): void => redirectToRegion(region)}
                  data-test-selector="regions-header-button-item"
                />
              ))}
            </ac-dropdown-button>
          </ac-flex>
        </ac-box>
      </ac-flex>
    </ac-box>
  </ac-flex>
);
