import { redirectToRegion } from 'src/utils/redirectToRegion';

import {
  RegionConfiguration,
  RegionsGroupConfiguration,
} from '@ac/library-utils/dist/declarations/config/ac.config';
import {
  ButtonPattern,
  ButtonTheme,
  DropdownButtonItemType,
  DropdownButtonSlot,
  IconName,
} from '@ac/web-components';

import './RegionButton.css';

interface Props {
  regionsGroup: RegionsGroupConfiguration;
  regionsInGroup: RegionConfiguration[];
}

interface SingleRegionInGroupButtonProps {
  region: RegionConfiguration;
}

const SingleRegionInGroupButton = ({
  region,
}: SingleRegionInGroupButtonProps): JSX.Element => (
  <ac-button
    pattern={ButtonPattern.primary}
    theme={ButtonTheme.light}
    onClick={(): void => redirectToRegion(region)}
    class="regions-button-container"
    data-test-selector="single-region-button"
  >
    <ac-button-content text={region.name} icon={IconName.shiji} />
  </ac-button>
);

const MultiRegionsInGroupButton = ({
  regionsGroup,
  regionsInGroup,
}: Props): JSX.Element => (
  <ac-dropdown-button
    pattern={ButtonPattern.primary}
    theme={ButtonTheme.light}
    isArrowVisible={true}
    class="regions-button-container"
    isBoundaryContainerApplied={false}
    data-test-selector="multi-regions-button"
  >
    <ac-button-content text={regionsGroup.name} icon={IconName.shiji} />

    {regionsInGroup.map((regionDropdown) => (
      <ac-dropdown-button-item
        label={regionDropdown.name}
        type={DropdownButtonItemType.default}
        slot={DropdownButtonSlot.items}
        key={regionDropdown.code}
        onClick={(): void => redirectToRegion(regionDropdown)}
        data-test-selector="multi-region-button-item"
      />
    ))}
  </ac-dropdown-button>
);

export const RegionButton = ({
  regionsGroup,
  regionsInGroup,
}: Props): JSX.Element => {
  const region = regionsInGroup[0];
  const manyRegionsInGroup = regionsInGroup.length > 1;

  return (
    <ac-box
      class={`regions-region-button regions-region-button-${regionsGroup.code.toLowerCase()}`}
    >
      {manyRegionsInGroup ? (
        <MultiRegionsInGroupButton
          regionsGroup={regionsGroup}
          regionsInGroup={regionsInGroup}
        />
      ) : (
        <SingleRegionInGroupButton region={region} />
      )}
    </ac-box>
  );
};
