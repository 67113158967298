import { RegionButton } from 'src/components/regionButton/RegionButton';

import {
  RegionConfiguration,
  RegionsGroupConfiguration,
} from '@ac/library-utils/dist/declarations/config/ac.config';

import { ReactComponent as Map } from './map.svg';

import './Body.css';

interface Props {
  regions: RegionConfiguration[];
  regionsGroups: RegionsGroupConfiguration[];
}

export const Body = ({ regions, regionsGroups }: Props): JSX.Element => {
  return (
    <div className="regions-body ac-spacing-left-lg ac-spacing-right-lg">
      <Map />
      {regionsGroups.map((regionGroup, index) => (
        <RegionButton
          key={index}
          regionsGroup={regionGroup}
          regionsInGroup={regions.filter(
            (region) => region.regionsGroupCode === regionGroup.code
          )}
        />
      ))}
    </div>
  );
};
